// MyPage.tsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Typography, IconButton } from '@mui/material';
import { UserType } from '../types/default';
import { API_ROUTE, FRONTEND_PATH } from '../constants/default';
import { CloudUpload as CloudUploadIcon, Cancel as CancelIcon } from "@mui/icons-material";

function MyPage({ user, onUserUpdate }: { user: UserType | undefined, onUserUpdate: Function }) {
  const [userId, setUserId] = useState(-1);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [avatar, setAvatar] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [inputKey, setInputKey] = useState(0);
  const navigate = useNavigate();

  const updateUserInfo = (user: UserType) => {
    console.log(user);
    onUserUpdate({
      userId: user.userId,
      name: user.name,
      email: user.email,
      imagePath: user.imagePath,
    })
    localStorage.setItem('user', JSON.stringify(user));
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate(FRONTEND_PATH.LOGIN);
        return;
      }

      const formData = new FormData();
      formData.append('name', name);
      formData.append('email', email);
      if (avatar) {
        formData.append('avatar', avatar);
      }

      const response = await axios.put(
        `https://api.interestudy.com/${API_ROUTE.USERS.UPDATE_USER}/${userId}`,
        formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
      );
      setSuccess(response.data.message);
      updateUserInfo(response.data.updatedData);
      setError('');
    } catch (err) {
      console.error(err);
      setError('Failed to update profile');
      setSuccess('');
    }
  };

  const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setAvatar(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleAvatarCancel = () => {
    setAvatar(null);
    setPreviewUrl(null);
    setInputKey(prevKey => prevKey + 1); // inputタグのkeyを更新
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token || !user) {
      navigate(FRONTEND_PATH.LOGIN);
    } else {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setUserId(user.userId);
      setName(user.name);
      setEmail(user.email);
    }
  }, [navigate]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Box
        component="form"
        onSubmit={handleSubmit}
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={4}
        borderRadius={4}
        boxShadow={3}
        bgcolor="background.paper"
      >
        <Typography variant="h4" mb={4}>
          My Page
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border: '2px dashed',
            borderColor: 'divider',
            borderRadius: 1,
            p: 2,
            mb: 4,
          }}
        >
          {previewUrl ? (
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                mt: 2,
              }}
            >
              <img src={previewUrl} alt="Avatar Preview" style={{ maxWidth: '100%', maxHeight: 200 }} />
              <IconButton
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  color: 'error.main',
                }}
                onClick={handleAvatarCancel}
              >
                <CancelIcon />
              </IconButton>
            </Box>
          ) : user?.imagePath ? (
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                mt: 2,
              }}
            >
              <img
                src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${user.imagePath}`}
                alt="Avatar"
                style={{ maxWidth: '100%', maxHeight: 200 }}
              />
            </Box>
          ) : (
            <>
              <CloudUploadIcon sx={{ fontSize: 48, mb: 1 }} />
              <Typography variant="body2" align="center">
                クリックしてファイルを選択してください
              </Typography>
            </>
          )}
          <input
            key={inputKey} // 動的なkeyを設定
            type="file"
            accept="image/*"
            onChange={handleAvatarChange}
            style={{ display: 'none' }}
            id="avatar-upload"
          />
          <label htmlFor="avatar-upload">
            <Button variant="outlined" component="span" sx={{ mt: 2 }}>
              Select Avatar
            </Button>
          </label>
        </Box><TextField
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="normal"
          required
        />
        {error && (
          <Typography color="error" mt={2}>
            {error}
          </Typography>
        )}
        {success && (
          <Typography color="success" mt={2}>
            {success}
          </Typography>
        )}
        <Button type="submit" variant="contained" color="primary" fullWidth sx={{ marginTop: 4 }}>
          Update Profile
        </Button>
      </Box>
    </Box>
  );
};

export default MyPage;
