// src/App.tsx
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline, Box } from '@mui/material';
import Header from './components/Header';
import theme from './theme';
import MyPage from './pages/Mypage';
import { useEffect, useState } from 'react';
import { UserType } from './types/default';
import { FRONTEND_PATH } from './constants/default';
import Register from './pages/Register';

function App() {
  const [user, setUser] = useState<UserType | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const handleUserUpdate = (updatedUser: UserType) => {
    setUser(prevUser => ({ ...prevUser, ...updatedUser }));
  };
  useEffect(() => {
    const token = localStorage.getItem('token');
    const userLocal = localStorage.getItem('user');
    if (token && userLocal) {
      setUser(JSON.parse(userLocal));
    }
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <div className="text-center p-5">Loading...</div>;
  }

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}
        >
          <Header user={user} onUserUpdate={handleUserUpdate} />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 0,
              m: 0,
            }}
          >
            <Routes>
              <Route path={FRONTEND_PATH.LOGIN} element={<Login onUserUpdate={handleUserUpdate} />} />
              <Route path={FRONTEND_PATH.DASHBOARD} element={<Dashboard user={user} />} />
              <Route path={FRONTEND_PATH.MYPAGE} element={<MyPage user={user} onUserUpdate={handleUserUpdate} />} />
              <Route path={FRONTEND_PATH.REGISTER} element={<Register onUserUpdate={handleUserUpdate} />} />
            </Routes>
          </Box>
        </Box>
      </ThemeProvider>
    </Router>
  );
}

export default App;
