import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  useMediaQuery,
  useTheme,
  Fab,
  IconButton,
  Typography,
} from "@mui/material";
import { Add as AddIcon, Close as CloseIcon, CloudUpload as CloudUploadIcon, Cancel as CancelIcon } from "@mui/icons-material";

type PostModalProps = {
  onSubmit: (formData: FormData) => void;
};

const PostModal: React.FC<PostModalProps> = ({ onSubmit }) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTitle("");
    setContent("");
    setImage(null);
    setPreviewUrl(null);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('content', content);
    if (image) {
      formData.append('image', image);
    }
    onSubmit(formData);
    setTitle('');
    setContent('');
    setImage(null);
    setPreviewUrl(null);
    setOpen(false);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setImage(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleImageCancel = () => {
    setImage(null);
    setPreviewUrl(null);
  };

  return (
    <>
      {!open && (
        <Fab
          color="primary"
          onClick={handleOpen}
          sx={{
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
          }}
        >
          <AddIcon />
        </Fab>
      )}
      <Dialog open={open} onClose={handleClose} fullScreen={isMobile} maxWidth="sm" fullWidth>
        {isMobile ? (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Button onClick={handleSubmit} variant="contained" color="primary">
              投稿
            </Button>
          </Box>
        ) : (
          <DialogTitle>新しい投稿</DialogTitle>
        )}
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
            <TextField
              label="タイトル"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              sx={{ marginTop: '10px' }}
              fullWidth
            />
            <TextField
              label="本文"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              multiline
              rows={4}
              fullWidth
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                border: '2px dashed',
                borderColor: 'divider',
                borderRadius: 1,
                p: 2,
              }}
            >
              {previewUrl ? (
                <Box
                  sx={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    mt: 2,
                  }}
                >
                  <img src={previewUrl} alt="Preview" style={{ maxWidth: '100%', maxHeight: 200 }} />
                  <IconButton
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      color: 'error.main',
                    }}
                    onClick={handleImageCancel}
                  >
                    <CancelIcon />
                  </IconButton>
                </Box>
              ) : (
                <>
                  <CloudUploadIcon sx={{ fontSize: 48, mb: 1 }} />
                  <Typography variant="body2" align="center">
                    クリックしてファイルを選択してください
                  </Typography>
                </>
              )}
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: 'none' }}
                id="image-upload"
              />
              <label htmlFor="image-upload">
                <Button variant="outlined" component="span" sx={{ mt: 2 }}>
                  画像を選択
                </Button>
              </label>
            </Box>
          </Box>
        </DialogContent>
        {!isMobile && (
          <DialogActions>
            <Button onClick={handleClose} sx={{ color: '#8D6E63' }}>キャンセル</Button>
            <Button onClick={handleSubmit} color="primary" variant="contained">
              投稿
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default PostModal;
