// src/components/Header.tsx
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ExitToApp from '@mui/icons-material/ExitToApp';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Avatar, Box, ListItemIcon } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home'; // 追加
import { UserType } from '../types/default';
import { FRONTEND_PATH, NO_HEADER_PAGES } from '../constants/default';

function Header({ user, onUserUpdate }: { user: UserType | undefined, onUserUpdate: Function }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [avatarUrl, setAvatarUrl] = useState<string>('');

  const handleUserIconClick = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleLogout = () => {
    // Perform logout logic here
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    onUserUpdate(undefined);
    navigate(FRONTEND_PATH.LOGIN);
  };

  const setTitleFromPath = (pathname: string) => {
    if (pathname === FRONTEND_PATH.DASHBOARD) {
      setTitle('日報');
    } else if (pathname === FRONTEND_PATH.MYPAGE) {
      setTitle('マイページ');
    } else {
      setTitle('Hello');
    }
  }

  useEffect(() => {
    if (user) {
      if (user.imagePath) {
        setAvatarUrl(`https://${process.env.REACT_APP_S3_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${user.imagePath}?t=${Date.now()}`);
      } else {
        setAvatarUrl('');
      }
    }
  }, [user]);

  useEffect(() => {
    setTitleFromPath(location.pathname);
    handleDrawerClose();
  }, [location.pathname]);


  if (NO_HEADER_PAGES.includes(location.pathname)) {
    return null; // Don't render the header on the login page
  } else if (!user) {
    return <div className="text-center p-5">Loading...</div>;
  }

  return (
    <AppBar position="static" color="secondary" elevation={0}>
      <Toolbar>
        <IconButton edge="start" color="inherit" onClick={handleUserIconClick}>
          <Avatar alt={user.name} src={avatarUrl} sx={{ width: 32, height: 32 }} />
        </IconButton>
        <Typography color="primary" variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
          {title}
        </Typography>
        <IconButton edge="end" color="inherit" onClick={handleLogout}>
          <ExitToApp color="primary" />
        </IconButton>
      </Toolbar>
      <Drawer anchor="left" open={isDrawerOpen} onClose={handleDrawerClose}>
        <Box sx={{ width: 250, padding: 2 }}>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Avatar
              sx={{ width: 60, height: 60, marginBottom: 1 }}
              alt={user.name} src={avatarUrl}
            >
              {/* ユーザアバターを表示 */}
            </Avatar>
            <Typography component="div" variant="subtitle1" sx={{ marginBottom: 0.5 }}>
              {/* ユーザ名を表示 */}
              {user?.name}
            </Typography>
            <Typography component="div" variant="body2" color="textSecondary">
              {/* メールアドレスを表示 */}
              {user?.email}
            </Typography>
          </Box>
          <List>
            <ListItem component={Link} to="/dashboard">
              <ListItemIcon>
                <HomeIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="ホーム" />
            </ListItem>
            <ListItem component={Link} to="/mypage">
              <ListItemIcon>
                <AccountCircleIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="マイページ" />
            </ListItem>
            <ListItem component={Link} to="/settings">
              <ListItemIcon>
                <SettingsIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="設定" />
            </ListItem>
            {/* 他のメニュー項目 */}
          </List>
        </Box>
      </Drawer>

    </AppBar>
  );
}

export default Header;
