import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { API_ROUTE, FRONTEND_PATH } from '../constants/default';

function Login({ onUserUpdate }: { onUserUpdate: Function }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        `https://api.interestudy.com/${API_ROUTE.USERS.LOGIN}`,
        { email, password }
      );
      const responseUser = JSON.stringify(res.data.user);

      onUserUpdate(res.data.user);

      localStorage.setItem('token', res.data.token);
      localStorage.setItem('user', responseUser);

      navigate(FRONTEND_PATH.DASHBOARD);
    } catch (err) {
      setError('Invalid email or password');
      console.log(err)
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Box
        component="form"
        onSubmit={handleSubmit}
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={4}
        borderRadius={4}
        boxShadow={3}
        bgcolor="background.paper"
      >
        <Typography variant="h4" mb={4}>
          Login
        </Typography>
        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          margin="normal"
          required
        />
        {error && (
          <Typography color="error" mt={2}>
            {error}
          </Typography>
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          ログイン
        </Button>
        <Link to="/register" style={{ textDecoration: 'none' }}>
          <Typography variant="body2" align="center">
            アカウントをお持ちでない方はこちら
          </Typography>
        </Link>
      </Box>
    </Box>
  );
};

export default Login;
