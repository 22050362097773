export const API_ROUTE = {
  USERS: {
    GET_USER_BY_ID: "users",
    UPDATE_USER: "users",
    LOGIN: "users/login",
    REGISTER: "users/register",
  },
  REPORTS: {
    GET_ALL_REPORTS: "reports",
    CREATE_REPORT: "reports",
  },
};

export const FRONTEND_PATH = {
  LOGIN: "/",
  REGISTER: "/register",
  DASHBOARD: "/dashboard",
  MYPAGE: "/mypage",
};

export const NO_HEADER_PAGES = [FRONTEND_PATH.LOGIN, FRONTEND_PATH.REGISTER];
