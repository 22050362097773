import { ListItem, ListItemText, Typography, Box, useMediaQuery, useTheme, ListItemAvatar, Avatar, styled } from '@mui/material';
import { formatDate } from '../util/report';
import { useNavigate } from 'react-router-dom';
import { ReportType, UserType } from '../types/default';
import axios from 'axios';
import { API_ROUTE, FRONTEND_PATH } from '../constants/default';
import { useEffect, useState } from 'react';


export default function ReportListItem({ report }: { report: ReportType }) {
  const formattedDate = formatDate(report.createdAt);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [reportUser, setReportUser] = useState<UserType>();
  const navigate = useNavigate();

  const getUserById = async () => {
    try {
      const res = await axios.get(
        `https://api.interestudy.com/${API_ROUTE.USERS.GET_USER_BY_ID}/${report.userId}`
      );
      console.log(res);
      setReportUser(res.data);
    } catch (error: any) {
      console.error('Error submitting post:', error);
      if (error.response.status === 401) {
        navigate(FRONTEND_PATH.LOGIN);
      }
    }
  }

  useEffect(() => {
    getUserById();
  }, [])

  const StyledListItem = styled(ListItem)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
  }));

  const StyledListItemText = styled(ListItemText)(({ theme }) => ({
    '& .MuiTypography-root': {
      color: theme.palette.text.primary,
    },
    '& .MuiTypography-body2': {
      color: theme.palette.text.secondary,
    },
  }));


  return (
    <StyledListItem alignItems="flex-start" sx={{ borderBottom: "0.1px solid rgb(213, 211, 211)", width: '100%', display: 'flex' }}>
      <ListItemAvatar>
        <Avatar alt={reportUser?.name} src={reportUser && `https://${process.env.REACT_APP_S3_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${reportUser.imagePath}`} />
      </ListItemAvatar>
      <StyledListItemText
        primary={
          <Box display="flex" justifyContent="space-between">
            <Typography component="div">{report.title}</Typography>
            <Typography component="div" variant="body2" color="text.secondary">
              {formattedDate}
            </Typography>
          </Box>
        }
        secondary={
          <>
            <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
              {report.content}
            </Typography>
            {report.imagePath && (
              <Box
                display="flex"
                justifyContent="center"
                sx={{ mt: 1 }}
              >
                <Box
                  component="img"
                  sx={{
                    height: 'auto',
                    width: isSmallScreen ? '90%' : '90%',
                    maxWidth: 600,
                    borderRadius: '5%',
                  }}
                  alt="report image"
                  src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${report.imagePath}`}
                />
              </Box>
            )}
          </>
        }
      />
    </StyledListItem>
  );
}