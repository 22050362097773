// src/theme.ts
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#8D6E63", // ブラウンカラー
    },
    secondary: {
      main: "#FFF8E1", // 明るいクリーム色
    },
    background: {
      default: "#FEFEFE", // 背景色を明るいクリーム色に
    },
    text: {
      primary: "#8D6E63",
    },
  },
  typography: {
    fontFamily: ["Noto Sans JP", "sans-serif"].join(","), // 日本語フォントを指定
  },
});

export default theme;
