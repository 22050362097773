import { useEffect, useState } from "react";
import axios from "axios";
import ReportListItem from "../components/ReportListItem";
import { Box, List, useMediaQuery, useTheme, Card, CardHeader } from '@mui/material';
import PostModal from '../components/PostModal';
import { useNavigate } from "react-router-dom";
import { ReportType, UserType } from "../types/default";
import { API_ROUTE, FRONTEND_PATH } from "../constants/default";

function Dashboard({ user }: { user: UserType | undefined }) {
  const [reports, setReports] = useState<ReportType[]>();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const getReports = async () => {
    try {
      const res = await axios.get(
        `https://api.interestudy.com/${API_ROUTE.REPORTS.GET_ALL_REPORTS}`
      );
      console.log(res);
      setReports(res.data);
    } catch (error: any) {
      console.error('Error submitting post:', error);
      if (error.response.status === 401) {
        navigate(FRONTEND_PATH.LOGIN);
      }
    }
  }

  const handleSubmitPost = async (formData: FormData) => {
    try {
      await axios.post(`https://api.interestudy.com/${API_ROUTE.REPORTS.CREATE_REPORT}`,
        formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      getReports();
    } catch (error) {
      console.error('Error submitting post:', error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token || !user) {
      navigate(FRONTEND_PATH.LOGIN);
    } else {
      // リクエストヘッダーにトークンを含める
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      getReports();
    }
  }, [navigate]);

  if (!reports) {
    return <div className="text-center p-5">Loading...</div>;
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      p={2}
      sx={{
        backgroundColor: theme.palette.background.default,
        minHeight: '100vh',
      }}
    >
      <Card sx={{
        backgroundColor: theme.palette.background.default, width: isSmallScreen ? '100%' : '50%'
      }}>
        <List>
          {reports.map((report) => (
            <ReportListItem key={report.id} report={report} />
          ))}
        </List>
      </Card>
      <PostModal onSubmit={handleSubmitPost} />
    </Box>
  );
}

export default Dashboard;
